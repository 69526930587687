<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterUsername
                    style="width:100%;"
                    v-model:value="state.params.username"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterAtasan
                    style="width:100%;"
                    v-model:value="state.params.atasan"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRole
                    style="width:100%;"
                    v-model:value="state.params.role"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    style="width:100%;"
                    v-model:value="state.params.active"
                    allow-clear
                    :options="state.statusLists"
                    placeholder="Pilih Status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <ASelect
                    v-model:value="state.params.level"
                    allow-clear
                    :options="state.levelLists"
                    placeholder="Pilih Level"
                    style="width:100%;"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AInputSearch
                    style="width:100%;"
                    v-model:value="state.params.q"
                    allow-clear
                    placeholder="Cari ..."/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    class="mr-3"
                    title="Tambah"
                    type="primary"
                    @click="btnForm">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Master-User-Salesman"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                  <a-tag v-if="text === 'Aktif'" color="#108ee9">Aktif</a-tag>
                  <a-tag v-else color="grey">Tidak Aktif</a-tag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Lihat"
                            placement="topLeft">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Edit"
                            placement="topLeft">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Detail Regional dan Area"
                            placement="topLeft">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnDetail(record)">
                                <i class="fe fe-globe" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>

                <template #expandedRowRender="{index}">
                    <MdTable
                        :columns="state.detailColumn"
                        :data-source="[state.data[index]]"
                        :pagination="false"/>
                </template>

            </MdTable>
        </div>

        <!-- form -->
        <FormCreateOrUpdate
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>

        <!-- detail -->
        <DetailRegionalAndArea
            v-if="visibleDetailModal"
            v-model:visible="visibleDetailModal"
            v-model:item="visibleDetailItemModal"/>
            
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterRole from '@/components/filter/FilterRole'
import FilterUsername from '@/components/filter/FilterUsername'
import FilterAtasan from '@/components/filter/FilterAtasan'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import FormCreateOrUpdate from './FormCreateOrUpdate'
import DetailRegionalAndArea from './DetailRegionalAndArea'
import { pickBy } from 'lodash'

export default defineComponent({
    components: {
        FormCreateOrUpdate,
        DetailRegionalAndArea,
        DownloadExcel,
        FilterRegional,
        FilterArea,
        FilterRole,
        FilterUsername,
        FilterAtasan,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nomor Pegawai',
                    dataIndex: 'nopeg',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'fullname',
                },
                {
                    title: 'Nomor Telepon',
                    dataIndex: 'phone',
                },
                {
                    title: 'Role',
                    dataIndex: 'role_name',
                },
                {
                    title: 'Group Area Id',
                    dataIndex: 'group_area',
                },
                {
                    title: 'Atasan User',
                    dataIndex: 'atasan',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            detailColumn: [
                {
                    title: 'Username',
                    dataIndex: 'username',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                },
            ],
            endpoint: '/api/users',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                region: [],
                area: [],
                role: [],
                username: [],
                atasan: [],
                level: [],
                active: null,
                page: 1,
                "per-page": 10,
            },
            statusLists: [
                {
                    label: 'Aktif',
                    value: 1,
                },
                {
                    label: 'Nonaktif',
                    value: 0,
                },
            ],
            levelLists: [
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint, {
                    params: {
                        ...pickBy(state.params),
                        active: state.params.active,
                    },
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((e, i) => {
                        e.key = i
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle detail
        const visibleDetailModal = ref(false);
        const visibleDetailItemModal = ref(null);

        const btnDetail = (item = null) => {
            visibleDetailModal.value = true
            visibleDetailItemModal.value = item
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            handleTableChange,
            state,
            errorMessage,
            visibleFormModal,
            visibleFormItemModal,
            btnForm,
            visibleDetailModal,
            visibleDetailItemModal,
            btnDetail,
        }
    },
})
</script>
