import { toRefs, computed, reactive, ref } from 'vue'
import store from '@/store'

export default function useFilter() {
  const findText = ref(null)

  const highlight = value => {
    return value.replace(new RegExp(findText.value, 'gi'), match => {
      return `<span style="background-color: yellow;">${match}</span>`
    })
  }

  return {
    highlight,
    findText,
  }
}
