/* eslint-disable */

<template>
  <a-select
    mode="multiple"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Atasan"
    allow-clear
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    :show-arrow="true"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="dropdownVisibleChange"
    @dropdownVisibleChange="dropdownVisibleChange"
    v-bind="$attrs"
  >
    <a-select-option v-for="d in data" :key="d.id" :label="d.name">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.name)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce } from 'lodash'
import useFilter from '@/composables/useFilter.js'

export default {
  props: {
    value: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const data = ref([])
    const { highlight, findText } = useFilter()

    const fetchData = debounce((q = null) => {
      apiClient
        .get('/api/filter/atasan', {
          params: { q },
        })
        .then(response => {
          const sorted = response.data
          data.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        })
        .catch(err => {
          console.error(err)
        })
    }, 600)

    onMounted(() => {
      fetchData()
    })

    const onSearch = debounce(value => {
      findText.value = value
      fetchData(value)
    }, 300)

    const dropdownVisibleChange = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
    }

    return {
      data,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      dropdownVisibleChange,
    }
  },
}
</script>

<style></style>
