<template>
    <a-modal
        :visible="visible"
        :title="`${state.title} - Kelola Pengguna`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- no pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="No Pegawai"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        name="nopeg"
                        has-feedback>
                        <AInput
                            placeholder="no pegawai"
                            :disabled="item.readOnly"
                            v-model:value="form.nopeg" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- nama pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nama Pengguna"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="fullname"
                        has-feedback>
                        <AInput
                            placeholder="nama pengguna"
                            :disabled="item.readOnly"
                            v-model:value="form.fullname" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- email -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Email"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="email"
                        has-feedback>
                        <AInput
                            placeholder="nama@domain.com"
                            :disabled="item.readOnly"
                            v-model:value="form.email" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Kata Sandi"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="password"
                        has-feedback>
                        <AInputPassword
                            placeholder="kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- konfirmasi kata sandi -->
            <a-row v-if="!form.id" class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Konfirmasi Kata Sandi"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="retype_password"
                        has-feedback>
                        <AInputPassword
                            placeholder="konfirmasi kata sandi"
                            :disabled="item.readOnly"
                            v-model:value="form.retype_password" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- phone -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nomor Telepon"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="phone"
                        has-feedback>
                        <AInput
                            addon-before="+62"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="^[1-9][0-9]*$"
                            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            placeholder="Ex : 81xxxxxxxxx"
                            v-model:value="form.phone"
                            :disabled="item.readOnly"
                            min="0"
                            maxlength="13" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- role -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Role"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="role_id"
                        has-feedback>
                        <FilterRole
                            style="width:100%"
                            :disabled="item.readOnly"
                            v-model:value="form.role_id"
                            show-search
                            :mode="null"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24" v-if="includes([1, 1259963], form.role_id)">
                    <AFormItem
                        label=""
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributorAll
                            v-if="form.role_id == 1"
                            style="width:100%"
                            :disabled="item.readOnly"
                            v-model:value="form.vendor_id"
                            show-search
                            :mode="null"
                            :join-options="form.vendor_id ? [{ id: form.vendor_id, name: `${item.vendor_code} - ${item.vendor_name}` }] : []" />
                        <FilterDistributor
                            v-else
                            style="width:100%"
                            :disabled="item.readOnly"
                            v-model:value="form.vendor_id"
                            show-search
                            :mode="null"
                            :join-options="form.vendor_id ? [{ id: form.vendor_id, name: `${item.vendor_code} - ${item.vendor_name}` }] : []" />
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- group mapping -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Group Mapping"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="group_role_id"
                        has-feedback>
                        <FilterGroupRole
                            :mode="null"
                            v-model:value="form.group_role_id"
                            v-model:role="form.role_id"
                            :display-label="form.group_area_name"
                            :disabled="item.readOnly"
                            style="width:100%"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- kabupaten kota -->
            <!-- <a-row
                v-if="form.role_id == 55414"
                class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Kabupaten/Kota"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="kabupaten"
                        has-feedback>
                        <FilterKabupatenGroupRole
                            v-model:value="form.kabupaten"
                            v-model:role="form.role_id"
                            v-model:group_role_id="form.group_role_id"
                            :disabled="item.readOnly"
                            style="width:100%"/>
                    </a-form-item>
                </a-col>
            </a-row> -->

            <a-row class="form-row">
                <a-col :sm="24">
                    <AFormItem
                        label="Status"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="active"
                        has-feedback>
                        <ARadioGroup
                            v-model:value="form.active"
                            :disabled="item.readOnly"
                            style="width:100%">
                            <a-radio :value="true">Aktif</a-radio>
                            <a-radio :value="false">Non Aktif</a-radio>
                        </ARadioGroup>
                    </AFormItem>
                </a-col>
            </a-row>

            <a-row v-if="!item.readOnly" class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterDistributorAll from '@/components/filter/FilterDistributorAll'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRole from '@/components/filter/FilterRole'
import FilterGroupRole from '@/components/filter/FilterGroupRole'
// import FilterKabupatenGroupRole from '@/components/filter/FilterKabupatenGroupRole'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        FilterDistributorAll,
        FilterDistributor,
        FilterRole,
        FilterGroupRole,
        // FilterKabupatenGroupRole,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            nopeg: null,
            fullname: null,
            email: null,
            password: null,
            retype_password: null,
            phone: null,
            active: null,
            role_id: [],
            group_role_id: [],
            kabupaten: [],
            vendor_id: [],
            // optional
            role_name: '',
            group_area_name: '',
            readOnly: false,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat',
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                fullname: [
                    {
                        required: true,
                        message: "Nama pegawai tidak boleh kosong!",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Email tidak boleh kosong!",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Kata sandi tidak boleh kosong!",
                    },
                    {
                        min: 8,
                        message: 'Kata Sandi minimal 8 karakter!',
                    },
                ],
                retype_password: [
                    {
                        required: true,
                        message: "Konfirmasi kata sandi tidak boleh kosong!",
                    },
                    {
                        validator: async (rule, value) => {
                            if (value !== form.password) {
                                return Promise.reject('Konfirmasi kata sandi harus sama dengan kata sandi!')
                            } else {
                                return Promise.resolve()
                            }
                        },
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: 'Nomor Telepon tidak boleh kosong!',
                    },
                    {
                        max: 12,
                        min: 0,
                        message: 'Maximal nomor telepon 12 angka',
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: 'Nomor Telepon tidak boleh memuat karakter selain angka',
                    },
                ],
                role_id: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Role tidak boleh kosong!',
                    },
                ],
                vendor_id: [
                    {
                        required: true,
                        message: 'Distributor tidak boleh kosong!',
                    },
                ],
                kabupaten: [
                    {
                        required: true,
                        message: 'Kabupaten tidak boleh kosong!',
                    },
                ],
                group_role_id: [
                    {
                        required: true,
                        type: 'number',
                        message: 'Group Mapping tidak boleh kosong!',
                    },
                ],
                active: [
                    {
                        required: true,
                        message: "Status tidak boleh kosong!",
                    },
                ],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post('/api/users')
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        syncRoleUser(form, data.id)

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                delete form.password
                delete form.retype_password

                form.put(`/api/users/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        syncRoleUser(form, form.id)

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        const syncRoleUser = (payload, userId = null) => {
            apiClient
                .post('/api/users/' + (payload.id || userId) + '/assign', payload)
                .then(({ data }) => {
                    if (!userId) {
                        message.success('Berhasil simpan!')
                    }
                })
                .catch(e => {
                    message.error(userId ? 'Gagal Update Role!' : 'Berhasil simpan tapi gagal menunjuk role, silahkan mengedit data baru tersebut!')
                })
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                form.nopeg = props.item.nopeg
                form.fullname = props.item.fullname
                form.email = props.item.email
                form.phone = props.item.phone
                form.active = props.item.active
                form.role_id = props.item.role_id
                form.vendor_id = props.item.vendor_id
                form.group_role_id = props.item.group_role_id
                form.kabupaten = props.item.kabupaten != null ? props.item.kabupaten.map(item => item.id) : []
                // optional name text
                form.role_name = props.item.role_name
                form.group_area_name = props.item.group_area
            }

            if (!props.item.readOnly) {
                state.title = !form.id ? 'Tambah' : 'Edit'
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            // lodash
            includes,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
